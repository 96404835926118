.App {
  text-align: center;
  margin-top: 40px;
  
}

.logodiv {
  text-align: center;
  width: 100%;
}

.logodiv img {
  width: 40%;
  margin-bottom: 20px;
}

table {
  margin: auto;
}

.trow {
  padding: 20px;
}

.tcol {
  border: 0.6px solid black;
  padding: 10px;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
}

@media screen and (max-width: 500px) {
  .logodiv img {
    width: 80%;
    margin-bottom: 20px;
  }
}